.Select {
  position: relative;
}

:is(.Select input::-webkit-contacts-auto-fill-button, .Select input::-webkit-credentials-auto-fill-button) {
  display: none !important;
}

.Select input::-ms-clear {
  display: none !important;
}

.Select input::-ms-reveal {
  display: none !important;
}

.Select, .Select div, .Select input, .Select span {
  box-sizing: border-box;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: .35;
}

.Select.is-disabled > .Select-control {
  background-color: #e9eaee;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-open > .Select-control {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Select.is-open > .Select-control .Select-arrow {
  border-width: 0 5px 5px;
  border-color: #0000 #0000 #999;
  top: -2px;
}

.Select.is-searchable.is-open > .Select-control, .Select.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.Select.is-focused > .Select-control {
  background: #fff;
}

.Select.is-focused:not(.is-open) > .Select-control {
  background: #fff;
  border-color: #007eff;
  box-shadow: inset 0 1px 1px #00000013, 0 0 0 3px #007eff1a;
}

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 42px;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover, .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #007eff;
  outline: none;
  text-decoration: underline;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background: #fff;
}

.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666;
}

.Select.Select--rtl {
  direction: rtl;
  text-align: right;
}

.Select-control {
  color: #333;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 36px;
  display: table;
  position: relative;
  overflow: hidden;
}

.Select-control:hover {
  box-shadow: 0 1px #0000000f;
}

.Select-control .Select-input:focus {
  background: #fff;
  outline: none;
}

.Select-placeholder {
  color: #8f97a1;
  font-style: italic;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  color: #aaa;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 34px;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.Select-input {
  vertical-align: middle;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
}

.Select-input > input {
  box-shadow: none;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 8px 0 12px;
  line-height: 17px;
  display: inline-block;
}

.is-focused .Select-input > input {
  cursor: text;
}

.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  display: table-cell;
  position: relative;
}

.Select-loading {
  box-sizing: border-box;
  vertical-align: middle;
  border: 2px solid #ccc;
  border-right-color: #333;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: .4s linear infinite Select-animation-spin;
  display: inline-block;
  position: relative;
}

.Select-clear-zone {
  color: #999;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 17px;
  animation: .2s Select-animation-fadeIn;
  display: table-cell;
  position: relative;
}

.Select-clear-zone:hover {
  color: #d0021b;
}

.Select-clear {
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

.Select--multi .Select-clear-zone {
  width: 17px;
}

.Select-arrow-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
  display: table-cell;
  position: relative;
}

.Select--rtl .Select-arrow-zone {
  padding-left: 5px;
  padding-right: 0;
}

.Select-arrow {
  border: 5px solid #0000;
  border-top-color: #999;
  border-bottom-width: 2.5px;
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
}

.Select-control > :last-child {
  padding-right: 5px;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  clip: rect(0, 0, 0, 0);
  float: left;
  width: 1px;
  height: 1px;
  margin: -1px;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Select-menu-outer {
  box-sizing: border-box;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
  max-height: 200px;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px #0000000f;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  color: #666;
  cursor: pointer;
  background-color: #fff;
  padding: 8px 10px;
  display: block;
}

.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.Select-option.is-selected {
  color: #333;
  background-color: #007eff0a;
}

.Select-option.is-focused {
  color: #333;
  background-color: #007eff14;
}

.Select-option.is-disabled {
  color: #ccc;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  padding: 8px 10px;
  display: block;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}

.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: 10px;
}

.Select--multi.has-value .Select-input {
  margin-left: 5px;
}

.Select--multi .Select-value {
  color: #007eff;
  vertical-align: top;
  background-color: #007eff14;
  border: 1px solid #007eff3d;
  border-radius: 2px;
  margin-top: 5px;
  margin-left: 5px;
  font-size: .9em;
  line-height: 1.4;
  display: inline-block;
}

.Select--multi .Select-value-icon, .Select--multi .Select-value-label {
  vertical-align: middle;
  display: inline-block;
}

.Select--multi .Select-value-label {
  cursor: default;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 2px 5px;
}

.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-right: 1px solid #007eff3d;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 1px 5px 3px;
}

.Select--multi .Select-value-icon:hover, .Select--multi .Select-value-icon:focus {
  color: #0071e6;
  background-color: #0071e614;
}

.Select--multi .Select-value-icon:active {
  background-color: #007eff3d;
}

.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 5px;
}

.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #007eff3d;
  border-right: none;
}

.Select--multi.is-disabled .Select-value {
  color: #333;
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon:hover, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:active {
  background-color: #fcfcfc;
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}

/*# sourceMappingURL=index.4679d80b.css.map */
